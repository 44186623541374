<template>
    <div class="add-customer-information">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">实操题</el-breadcrumb-item>
            <el-breadcrumb-item>添加客户信息</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="steps-wrapper">
            <div class="step-item" :class="{ current: [1, 2].includes(stepActive) }">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建竞赛模板</div>
            </div>
            <div class="step-item" :class="{ current: [2].includes(stepActive) }">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 1">
            <div class="create-content-top">
                <div class="create-content-left">
                    <el-button type="primary" plain @click="importCustomerInformation" style="margin-right: 10px">
                        导入客户信息
                    </el-button>
                    <input
                        type="file"
                        ref="excelInput"
                        accept="excel/*"
                        title=""
                        @change="changeExcel($event)"
                        style="display: none"
                    />
                    <el-button type="primary" plain @click="exportCustomerInformation">导出客户信息</el-button>
                    <el-button type="primary" plain @click="downloadTemplate">下载模板</el-button>
                </div>
                <div class="create-content-right">
                    <label class="label-name">客户信息模板名称</label>
                    <el-input type="text" v-model="c_name" placeholder="请输入客户信息模板名称"></el-input>
                </div>
            </div>
            <div class="create-content-table">
                <el-scrollbar>
                    <el-table
                        :data="customerTable"
                        style="width: 100%; flex: 1"
                        size="medium"
                        :tree-props="{ children: 'order_data', hasChildren: 'hasChildren' }"
                        :row-key="getRowKey"
                        :expand-row-keys="expands"
                        height="470"
                        :header-cell-style="{
                            fontWeight: 'normal',
                            color: '#666',
                            background: '#eee',
                            fontSize: '16px',
                            height: '60px',
                        }"
                        class="customTable"
                        :cell-style="{ fontSize: '12px', color: '#333', height: '70px' }"
                    >
                        <el-table-column prop="nick_name" label="客户名称"></el-table-column>
                        <!-- <el-table-column prop="name" label="客户姓名" align="center"></el-table-column> -->
                        <el-table-column prop="price" label="客单价（元）" align="center"></el-table-column>
                        <el-table-column prop="buy_time" label="购买时间" align="center">
                            <template slot-scope="scope" v-if="scope.row.buy_time != ''">{{
                                timeFormat(scope.row.buy_time)
                            }}</template>
                        </el-table-column>
                        <el-table-column prop="heart" label="评价" align="center" style="width: 146px">
                            <template slot-scope="scope">
                                <div class="evaluate-box">
                                    <el-rate v-model="scope.row.heart" disabled text-color="#ff9900"> </el-rate>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="order_num" label="交易笔数" align="center" style="width: 146px"></el-table-column>
                        <el-table-column prop="change_num" label="订单改价次数" align="center"></el-table-column>
                        <el-table-column label="操作" align="center" width="220px">
                            <template slot-scope="scope">
                                <div class="link-list">
                                    <!-- <el-link
                                        type="primary"
                                        v-if="'order_data' in scope.row"
                                        :underline="false"
                                        @click="addPurchase(scope.row, scope.$index)"
                                        >增加交易笔数
                                    </el-link>
                                    <el-link type="primary" v-else :underline="false" style="visibility: hidden">增加交易笔数</el-link> -->
                                    <el-link type="success" :underline="false" @click="editPurchase(scope.row, scope.$index)">
                                        编辑
                                    </el-link>
                                    <el-link type="danger" :underline="false" @click="deletePurchase(scope.row, scope.$index)"
                                        >删除</el-link
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-scrollbar>
            </div>

            <div class="add-footer">
                <el-button type="primary" plain @click="addCustomer()">添加客户信息</el-button>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="success-content">
                <img src="../../../assets/images/admin/success.png" alt="" />
                <p class="text">创建成功</p>
                <el-button @click="prevBtn(stepActive)" type="primary" v-if="stepActive === 2">返回列表</el-button>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" v-if="stepActive === 1">上一步</el-button>
            <el-button type="primary" @click="nextBtn()" v-if="stepActive === 1">下一步</el-button>
        </div>
        <!--S 添加客户信息弹窗-->
        <el-dialog
            :title="customerTitle"
            :visible.sync="dialogAddCustomer"
            custom-class="green-dialog02"
            width="500px"
            @close="resetForm()"
            :close-on-click-modal="false"
        >
            <el-scrollbar class="customer-scrollbar" :native="false">
                <el-form
                    :model="addCustomerForm"
                    ref="addCustomerForm"
                    :rules="resourcesRules"
                    :hide-required-asterisk="true"
                    label-position="right"
                    label-width="156px"
                >
                    <el-form-item
                        label="客户名称"
                        v-if="customerTitle === '添加客户信息' || customerTitle === '编辑客户信息'"
                        prop="nick_name"
                    >
                        <el-input
                            v-model="addCustomerForm.nick_name"
                            ref="nameInput"
                            autocomplete="off"
                            style="width: 300px"
                            placeholder="请输入客户名称"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item
                        label="客户姓名"
                        v-if="customerTitle === '添加客户信息' || customerTitle === '编辑客户信息'"
                        prop="name"
                    >
                        <el-input
                            v-model="addCustomerForm.name"
                            autocomplete="off"
                            style="width: 300px"
                            placeholder="请输入客户姓名"
                        ></el-input>
                    </el-form-item> -->
                    <el-form-item label="性别" v-if="customerTitle === '添加客户信息' || customerTitle === '编辑客户信息'" prop="sex">
                        <el-select v-model="addCustomerForm.sex" placeholder="请选择性别" style="width: 300px" clearable>
                            <el-option v-for="item in sexList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="年龄" v-if="customerTitle === '添加客户信息' || customerTitle === '编辑客户信息'" prop="age">
                        <el-input v-model.number="addCustomerForm.age" placeholder="请输入年龄" style="width: 300px"></el-input>
                        <!--                        <el-input-number v-model="addCustomerForm.age" :min="0" :max="99" :precision="0"-->
                        <!--                                         :controls="false" placeholder="请输入年龄" style="width:300px"></el-input-number>-->
                    </el-form-item>
                    <el-form-item
                        label="联系电话"
                        v-if="customerTitle === '添加客户信息' || customerTitle === '编辑客户信息'"
                        prop="telephone"
                    >
                        <el-input v-model="addCustomerForm.telephone" placeholder="请输入联系电话" style="width: 300px"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="收货地址"
                        v-if="customerTitle === '添加客户信息' || customerTitle === '编辑客户信息'"
                        prop="address"
                    >
                        <el-input type="textarea" v-model="addCustomerForm.address" style="width: 300px"></el-input>
                    </el-form-item>
                    <el-form-item label="购买时间" prop="time">
                        <el-date-picker
                            style="width: 300px"
                            v-model="addCustomerForm.time"
                            type="datetime"
                            value-format="timestamp"
                            placeholder="选择日期时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="客单价" prop="price">
                        <el-input-number
                            v-model="addCustomerForm.price"
                            :min="0"
                            :precision="0"
                            :controls="false"
                            placeholder="请输入客单价"
                            style="width: 300px"
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="页面停留时间(s)" prop="residence_time">
                        <el-input-number
                            v-model="addCustomerForm.residence_time"
                            :min="0"
                            :precision="0"
                            :controls="false"
                            placeholder="请输入页面停留时间"
                            style="width: 300px"
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="订单改价次数" prop="price_change">
                        <el-input-number
                            v-model="addCustomerForm.price_change"
                            :min="0"
                            :precision="0"
                            :controls="false"
                            placeholder="请输入订单改价次数"
                            style="width: 300px"
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="评价" prop="evaluate">
                        <el-select v-model="addCustomerForm.evaluate" placeholder="请选择评价" style="width: 300px" clearable>
                            <el-option
                                v-for="item in evaluateList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="交易笔数" prop="order_num">
                        <el-input-number
                            v-model="addCustomerForm.order_num"
                            :min="0"
                            :precision="0"
                            :controls="false"
                            placeholder="请输入交易笔数"
                            style="width: 300px"
                        ></el-input-number>
                    </el-form-item>
                </el-form>
            </el-scrollbar>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddCustomer = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addCustomerForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--E 添加客户信息弹窗-->
    </div>
</template>

<script>
import {
    customerInfoEdit,
    customerInfoExcelInto,
    customerInfoGetDetail,
    customerInfoExcelOut,
    customerInfoGerExcelData,
} from "@/utils/apis";

export default {
    name: "AddCustomerInformation",
    data() {
        //手机号验证
        let checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("手机号不能为空"));
            } else {
                const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error("请输入正确的手机号"));
                }
            }
        };
        let validateAge = (rule, value, callback) => {
            let age = Number(value);
            if (isNaN(age)) {
                return callback(new Error("请输入正确的年龄1-120数字类型"));
            }
            if (age <= 0 || age > 120) {
                return callback(new Error("请输入正确的年龄1-120数字类型"));
            }
            callback();
        };
        return {
            //步骤
            stepActive: 1,
            c_name: "", //客户信息模板名称
            customerTable: [],
            customerTitle: "", //添加客户信息标题
            dialogAddCustomer: false, //添加客户信息弹窗
            customerTableIndex: -1,
            customerTableSecondIndex: -1, //
            customerTableId: 0, //添加订单修改次数时的客户信息ID
            addCustomerForm: {
                nick_name: "",
                // name: "",
                sex: "", //性别
                age: void 0,
                telephone: "",
                address: "",
                time: void 0,
                price: void 0,
                residence_time: void 0,
                price_change: void 0,
                evaluate: "",
                order_num: void 0,
            },
            // addCustomerForm: {
            //     nick_name: "123",
            //     // name: "",
            //     sex: "1", //性别
            //     age: 12,
            //     telephone: "18650334586",
            //     address: "12",
            //     time: 1649929269820,
            //     price: 12,
            //     residence_time: 12,
            //     price_change: 12,
            //     evaluate: 3,
            //     order_num: 12,
            // },
            expands: [],
            addCustomerIndex: [],
            sexList: [
                {
                    key: 1,
                    value: "男",
                },
                {
                    key: 2,
                    value: "女",
                },
            ],
            evaluateList: [
                {
                    value: 1,
                    label: "一星",
                },
                {
                    value: 2,
                    label: "二星",
                },
                {
                    value: 3,
                    label: "三星",
                },
                {
                    value: 4,
                    label: "四星",
                },
                {
                    value: 5,
                    label: "五星",
                },
            ],
            resourcesRules: {
                nick_name: [
                    { required: true, message: "请输入客户名称", trigger: "change" },
                    { min: 2, max: 32, message: "长度在 2 到 32 个字符", trigger: "blur" },
                ],
                // name: [
                //     { required: true, message: "请输入客户姓名", trigger: "blur" },
                //     { min: 2, max: 32, message: "长度在 2 到 32 个字符", trigger: "blur" },
                // ],
                sex: [{ required: true, message: "请选择性别", trigger: "change" }],
                // age: {required: true, message: '请s年龄', trigger: 'blur'},
                age: { validator: validateAge, trigger: ["blur", "change"] },
                telephone: { validator: checkPhone, trigger: ["blur", "change"] },
                address: { required: true, message: "请输入收货地址", trigger: "blur" },
                time: { required: true, message: "请选择购买时间", trigger: "blur" },
                price: { required: true, message: "请输入客单价", trigger: "blur" },
                residence_time: { required: true, message: "请输入页面停留时间", trigger: "blur" },
                price_change: { required: true, message: "请输入订单改价次数", trigger: "blur" },
                evaluate: [{ required: true, message: "请选择评价", trigger: "change" }],
            },
            //导入客户信息
            customer_info: "",
            excelFile: "",
            excelFileName: "",
            keyId: 0,
        };
    },

    mounted() {
        // this.expands = this.getRowKeys(this.customerTable);
        if (this.$route.query.id) {
            //   获取商品详情
            let param = {
                customer_info_group_id: this.$route.query.id,
            };
            customerInfoGetDetail(param)
                .then((res) => {
                    this.c_name = res.data.group_data.customer_info_group_name;
                    this.customerTable = res.data.list;

                    console.log("customerInfoGetDetail", res);
                    let t = new Date().getTime();
                    let cId = 0;
                    // let obj = { buy_time: "", change_num: "", heart: "", price: "" };
                    let obj = { buy_time: "", change_num: "", heart: "", price: "" };
                    this.customerTable.forEach((item, index) => {
                        item.cId = t;
                        t++;
                        // if (item.buy_time.indexOf("-") > -1) {
                        //     item.buy_time = new Date(item.buy_time).getTime() / 1000;
                        // }
                        // item.change_num = parseInt(item.change_num);
                        // item.heart = parseInt(item.heart);
                        // item.price = parseInt(item.price);
                        item.age = parseInt(item.age);
                        item.sex = parseInt(item.sex);
                        item.buy_time = parseInt(+new Date(item.buy_time) / 1000);
                        item.price = parseInt(item.price);
                        // item.heart = Number(obj.heart);
                        // item.buy_time = obj.buy_time;
                        // item.change_num = obj.buy_time;
                        // item.price = obj.price;
                        // if (item.order_data && item.order_data.length > 0) {
                        //     item.order_data.forEach((item1, index1) => {
                        //         item1.cId = cId;
                        //         item1.pId = item.cId;
                        //         cId++;
                        //         if (item1.buy_time.indexOf("-") > -1) {
                        //             item1.buy_time = new Date(item1.buy_time).getTime() / 1000;
                        //         }
                        //         item1.change_num = parseInt(item1.change_num);
                        //         item1.heart = parseInt(item1.heart);
                        //         item1.price = parseInt(item1.price);
                        //     });
                        // }
                    });
                    console.log(this.customerTable);
                })
                .catch((err) => {
                    console.error("err", err);
                });
        }
    },
    methods: {
        getRowKey(row) {
            return row.cId;
        },
        getRowKeys(data) {
            let arr = [];
            if (data.length === 0) {
                return arr;
            }
            arr.push(data[0].cId);
            if (data[0].order_data && data[0].order_data.length > 0) {
                let result = this.getRowKeys(data[0].order_data);
                arr = arr.concat(result);
            }
            return arr;
        },
        //添加客户信息
        addCustomer() {
            this.dialogAddCustomer = !this.dialogAddCustomer;
            this.customerTitle = "添加客户信息";
        },
        //增加交易笔数
        addPurchase(row) {
            // this.customerTableIndex = index;
            this.customerTableId = row.cId;
            this.dialogAddCustomer = !this.dialogAddCustomer;
            this.customerTitle = "添加购买次数";
            for (let i in this.customerTable) {
                if (this.customerTable[i].cId === row.cId) {
                    this.customerTableIndex = i;
                    break;
                }
            }
        },
        //导入客户信息
        importCustomerInformation() {
            this.$refs.excelInput.value = null;
            this.$refs.excelInput.click();
        },
        changeExcel(event) {
            if (event.target.files.length === 0) return;
            if (event.target.files[0].size > 1024 * 1024 * 200) {
                this.$message.warning("文件超过200M");
                return;
            } else {
                let fileList = event.target.files[0];
                this.excelFile = fileList;
                this.excelFileName = fileList.name;
                let url = "";
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(",") + 1);
                    that.customer_info = "data:excel/*," + url;
                };
            }
            let formData = new FormData();
            formData.append("customer_info", this.excelFile);
            customerInfoExcelInto(formData)
                .then((res) => {
                    this.c_name = res.data.group_data;
                    let t = new Date().getTime();
                    let arr = [];
                    arr = res.data.list;
                    // console.log(arr);
                    let obj = { buy_time: "", change_num: "", heart: "", price: "" };
                    arr.forEach((item, index) => {
                        this.keyId++;
                        item.cId = this.keyId;
                        // if (item.buy_time.indexOf("-") > -1) {
                        //     item.buy_time = new Date(item.buy_time).getTime()/1000;
                        // }
                        // item.change_num = parseInt(item.change_num);
                        // item.heart = parseInt(item.heart);
                        // item.price = parseInt(item.price);
                        item.age = parseInt(item.age);
                        item.sex = parseInt(item.sex);
                        item.buy_time = parseInt(+new Date(item.buy_time) / 1000);
                        item.change_num = item.change_num;
                        item.heart = item.heart * 1;
                        item.price = parseInt(item.price);
                        // item.buy_time = obj.buy_time;
                        // item.change_num = obj.buy_time;
                        // item.heart = obj.heart;
                        // item.price = obj.price;

                        if (item.order_data && item.order_data.length > 0) {
                            item.order_data.forEach((item1, index1) => {
                                item1.cId = `${item.cId}${index1}`;
                                item1.pId = item.cId;
                                if (item1.buy_time.indexOf("-") > -1) {
                                    item1.buy_time = new Date(item1.buy_time).getTime() / 1000;
                                }
                                item1.change_num = parseInt(item1.change_num);
                                item1.heart = parseInt(item1.heart);
                                item1.price = parseInt(item1.price);
                                item1.sex = item1.sex === "男" ? 1 : 2;
                            });
                        }
                        this.customerTable.push(item);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 导出
        exportCustomerInformation() {
            let arr = JSON.parse(JSON.stringify(this.customerTable));
            console.log(arr);
            // return void 0;
            // arr.forEach((item, index) => {
            //     delete item.cId;
            //     if (item.buy_time.toString().length === 13) {
            //         item.buy_time = item.buy_time / 1000;
            //     }
            //     if (item.order_data.length > 0) {
            //         item.order_data.forEach((item1, index1) => {
            //             delete item1.cId;
            //             delete item1.pId;
            //             if (item1.buy_time.toString().length === 13) {
            //                 item1.buy_time = item1.buy_time / 1000;
            //             }
            //         });
            //     } else {
            //         delete item.order_data;
            //     }
            // });
            let param = {
                customer_info_group_name: this.c_name,
                customer_data: JSON.stringify(arr),
            };
            customerInfoGerExcelData(param)
                .then((res) => {
                    console.log(res, "kehu");
                    if (res.code === 200) {
                        window.location.href = localStorage.getItem("hosturl") + "customerInfo/excelOut";
                        return;
                        // customerInfoExcelOut().then((res) => {
                        //     // console.log(res, '1111111')
                        // }).catch((err) => {
                        //     console.log(err);
                        // });
                    } else {
                        this.$message.success(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //下载模板
        downloadTemplate() {
            window.location.href = localStorage.getItem("hosturl") + "template/customer_info.xlsx";
        },
        //编辑
        editPurchase(row, index) {
            console.log("row", row);
            this.dialogAddCustomer = true;
            this.addCustomerForm.time = row.buy_time * 1000;
            this.addCustomerForm.residence_time = row.stand_time;
            this.addCustomerForm.price_change = row.change_num;
            this.addCustomerForm.evaluate = row.heart;
            this.addCustomerForm.price = row.price;
            this.addCustomerForm.order_num = row.order_num;
            if (row.pId) {
                //
                for (let i in this.customerTable) {
                    if (this.customerTable[i].cId === row.pId) {
                        for (let j in this.customerTable[i].order_data) {
                            if (this.customerTable[i].order_data[j].cId === row.cId) {
                                this.customerTableSecondIndex = j;
                                break;
                            }
                        }
                        this.customerTableIndex = i;
                        break;
                    }
                }
                this.customerTitle = "编辑购买次数";
            } else {
                for (let i in this.customerTable) {
                    if (this.customerTable[i].cId === row.cId) {
                        this.customerTableIndex = i;
                        break;
                    }
                }
                this.addCustomerForm.nick_name = row.nick_name;
                this.addCustomerForm.name = row.name;
                this.addCustomerForm.sex = row.sex;
                this.addCustomerForm.age = row.age;
                this.addCustomerForm.telephone = row.phone;
                this.addCustomerForm.address = row.address;
                this.customerTitle = "编辑客户信息";
            }
        },
        // 上一步
        prevBtn(index) {
            if (index === 1) {
                this.$router.go(-1);
            } else {
                this.$router.push("/practice/customerInformation");
            }
        },
        //下一步
        nextBtn() {
            let arr = JSON.parse(JSON.stringify(this.customerTable));
            arr.forEach((item, index) => {
                delete item.cId;
                // if(item.buy_time.toString().length===13){
                //     item.buy_time = item.buy_time /1000
                // }
                if (item.order_data && item.order_data.length > 0) {
                    item.order_data.forEach((item1, index1) => {
                        delete item1.cId;
                        delete item1.pId;
                        if (item1.buy_time.toString().length === 13) {
                            item1.buy_time = item1.buy_time / 1000;
                        }
                    });
                } else {
                    delete item.order_data;
                }
            });
            let param = {
                customer_info_group_name: this.c_name,
                customer_data: JSON.stringify(arr),
            };
            if (this.$route.query.id) {
                param.customer_info_group_id = this.$route.query.id;
            }
            customerInfoEdit(param)
                .then((res) => {
                    this.stepActive = 2;
                    this.$message.success(res.msg);
                    console.log("customerInfoEdit", res);
                })
                .catch((err) => {
                    console.error("err", err);
                });
        },
        //确定
        addToForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let timestamp = this.addCustomerForm.time;
                    if (timestamp.toString().length === 13) {
                        timestamp = timestamp / 1000;
                    }
                    if (this.customerTitle === "添加客户信息" || this.customerTitle === "添加购买次数") {
                        //新增
                        if (this.customerTableId > 0) {
                            // 订单修改次数
                            let ob = {
                                cId: new Date().getTime(),
                                pId: this.customerTable[this.customerTableIndex].cId,
                                price: this.addCustomerForm.price,
                                buy_time: timestamp,
                                stand_time: this.addCustomerForm.residence_time,
                                change_num: this.addCustomerForm.price_change,
                                heart: this.addCustomerForm.evaluate,
                                order_num: this.addCustomerForm.order_num,
                            };
                            this.customerTable[this.customerTableIndex]["order_data"].push(ob);
                        } else {
                            let ob = {
                                cId: new Date().getTime(),
                                nick_name: this.addCustomerForm.nick_name,
                                name: this.addCustomerForm.name,
                                sex: this.addCustomerForm.sex,
                                age: this.addCustomerForm.age,
                                phone: this.addCustomerForm.telephone,
                                address: this.addCustomerForm.address,
                                price: this.addCustomerForm.price,
                                buy_time: timestamp,
                                stand_time: this.addCustomerForm.residence_time,
                                change_num: this.addCustomerForm.price_change,
                                heart: this.addCustomerForm.evaluate,
                                order_num: this.addCustomerForm.order_num,
                                order_data: [],
                            };
                            this.customerTable.push(ob);
                        }
                    } else {
                        //    编辑
                        if (this.customerTitle === "编辑购买次数") {
                            // 订单修改次数
                            let ob = {
                                cId: this.customerTable[this.customerTableIndex].order_data[this.customerTableSecondIndex].cId,
                                pId: this.customerTable[this.customerTableIndex].order_data[this.customerTableSecondIndex].pId,
                                price: this.addCustomerForm.price,
                                buy_time: timestamp,
                                stand_time: this.addCustomerForm.residence_time,
                                change_num: this.addCustomerForm.price_change,
                                heart: this.addCustomerForm.evaluate,
                            };
                            this.customerTable[this.customerTableIndex].order_data[this.customerTableSecondIndex] = ob;
                        } else {
                            let ob = {
                                cId: this.customerTable[this.customerTableIndex].cId,
                                nick_name: this.addCustomerForm.nick_name,
                                name: this.addCustomerForm.name,
                                sex: this.addCustomerForm.sex,
                                age: this.addCustomerForm.age,
                                phone: this.addCustomerForm.telephone,
                                address: this.addCustomerForm.address,
                                price: this.addCustomerForm.price,
                                buy_time: timestamp,
                                stand_time: this.addCustomerForm.residence_time,
                                change_num: this.addCustomerForm.price_change,
                                heart: this.addCustomerForm.evaluate,
                                order_data: this.customerTable[this.customerTableIndex].order_data,
                                order_num: this.addCustomerForm.order_num,
                            };
                            // this.customerTable[this.customerTableIndex] = ob;
                            this.customerTable.splice(this.customerTableIndex, 1, ob)
                        }
                    }
                    this.dialogAddCustomer = false;
                    this.customerTableId = 0;
                    this.customerTableIndex = -1;
                    this.customerTableSecondIndex = -1;
                    // orderManagementContent
                } else {
                    return false;
                }
            });
        },
        //时间处理
        timeFormat(timestamp) {
            if (timestamp.toString().length === 10) {
                timestamp = timestamp * 1000;
            }
            let date = new Date(timestamp);
            let y = date.getFullYear();
            let m = date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
            let d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
            return y + "-" + m + "-" + d;
        },
        //删除
        deletePurchase(row, index) {
            if (row.pId) {
                for (let i in this.customerTable) {
                    if (this.customerTable[i].cId === row.pId) {
                        for (let j in this.customerTable[i].order_data) {
                            if (this.customerTable[i].order_data[j].cId === row.cId) {
                                this.customerTable[i].order_data.splice(j, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
            } else {
                for (let i in this.customerTable) {
                    if (this.customerTable[i].cId === row.cId) {
                        if (this.customerTable[i].order_data && this.customerTable[i].order_data.length > 0) {
                            // 判断是否包含子级
                            this.$message.warning("请先删除购买次数");
                        } else {
                            this.customerTable.splice(index, 1);
                        }
                        break;
                    }
                }
            }
        },
        resetForm(formName) {
            this.$refs.addCustomerForm.resetFields();
            this.addCustomerForm = this.$options.data().addCustomerForm;
        },
    },
};
</script>

<style scoped lang="scss">
.add-customer-information {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .create-content {
        flex: 1;
        .create-content-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            // .create-content-left {
            // }
            .create-content-right {
                display: flex;
                align-items: center;
                .label-name {
                    min-width: 124px;
                }
            }
        }
        .evaluate-box {
            display: flex;
            width: 150px;
            ::v-deep .el-rate__icon {
                font-size: 19px;
            }
        }
        .add-footer {
            margin-top: 20px;
        }
        .success-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 140px;
            .text {
                font-size: 16px;
                color: #333;
                font-weight: bold;
            }
        }
    }

    /*.customer-scrollbar {*/
    /*    height: 100%;*/
    /*    ::v-deep .el-scrollbar__wrap {*/
    /*        overflow-x: hidden;*/
    /*    }*/
    /*}*/
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }
    ::v-deep .el-textarea__inner {
        height: 80px;
        resize: none;
    }

    /*::v-deep .el-dialog__body {*/
    /*    height: calc(100vh - 360px);*/
    /*    padding: 20px 20px;*/
    /*}*/

    .bottom-btn {
        text-align: center;
        margin-bottom: 50px;
    }

    .dialog-footer {
        text-align: center;
    }
}
</style>
